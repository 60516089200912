import { NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()
export class IconsModule {
    /**
     * Constructor
     */
    constructor(
        private _domSanitizer: DomSanitizer,
        private _matIconRegistry: MatIconRegistry
    ) {
        // Register icon sets
        this._matIconRegistry.addSvgIconSet(
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'mat_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'iconsmind',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'feather',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_outline',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg')
        );
        this._matIconRegistry.addSvgIconSetInNamespace(
            'heroicons_solid',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg')
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'vizio',
            'aspen-butt',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vizio/aspen-butt.svg')
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'vizio',
            'aspen-pumpkin',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/vizio/aspen-pumpkin.svg'
            )
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'vizio',
            'aspen-snowman',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/vizio/aspen-snowman.svg'
            )
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'vizio',
            'aspen-filled',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/vizio/aspen-filled.svg')
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'vizio',
            'aspen-name-filled',
            this._domSanitizer.bypassSecurityTrustResourceUrl(
                'assets/icons/vizio/aspen-name-filled.svg'
            )
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'charts',
            'area-chart',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/charts/area-chart.svg')
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'charts',
            'bar-chart',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/charts/bar-chart.svg')
        );
        this._matIconRegistry.addSvgIconInNamespace(
            'charts',
            'line-chart',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/charts/line-chart.svg')
        );
    }
}
