<div class="snowflake-container">
    <div class="snowflakes-wrapper">
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>
        <div class="snowflake">
            <div class="inner">❅</div>
        </div>

        @for (flake of numSnowflakes; track $index) {
            <div class="brandon-snowflake"></div>
        }
    </div>
</div>
<div [class]="snowmanClass"></div>
<div class="snow-accumulation"></div>
<audio autoplay loop #audioNocturne>
    <source src="/assets/audio/nocturne-roman.mp3" type="audio/mpeg" />
</audio>
<audio #audioSnowmanLow>
    <source src="/assets/audio/snowman/snowman-low.wav" type="audio/wav" preload="auto" />
</audio>
<audio #audioSnowmanMid>
    <source src="/assets/audio/snowman/snowman-mid.wav" type="audio/wav" preload="auto" />
</audio>
<audio #audioSnowmanHigh>
    <source src="/assets/audio/snowman/snowman-high.wav" type="audio/wav" preload="auto" />
</audio>
<audio #audioSnowmanFinal>
    <source src="/assets/audio/snowman/snowman-final.wav" type="audio/wav" preload="auto" />
</audio>
