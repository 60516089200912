// Angular Imports
import { Component, inject } from '@angular/core';

// Project Imports
import { EasterEggsStateService } from '../services/easter-eggs-state.service';
import { HalloweenComponent } from '../components/halloween/halloween.component';
import { WinterWonderlandComponent } from '../components/winter-wonderland/winter-wonderland.component';

@Component({
    standalone: true,
    selector: 'easter-eggs-container',
    templateUrl: './easter-eggs-container.component.html',
    styleUrls: ['./easter-eggs-container.component.scss'],
    imports: [HalloweenComponent, WinterWonderlandComponent]
})
export class EasterEggsContainerComponent {
    public readonly eeStateService = inject(EasterEggsStateService);

    constructor() {}
}
