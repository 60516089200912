// Angular Imports
import { Component, Inject, OnInit } from '@angular/core';
import {
    ActivationStart,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router
} from '@angular/router';

// Third Party Imports
import { filter, map } from 'rxjs';

// Project Imports
import { GoogleAnalyticsService } from '@core/services/google-analytics.service';
import { ENV_TOKEN } from '@core/environment/environment.provider';
import { IEnvironment } from '@core/environment/environment.types';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public isLoading$ = this.router.events.pipe(
        filter((event) => {
            return (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError ||
                event instanceof ActivationStart
            );
        }),
        map((event) => {
            if (event instanceof ActivationStart) {
                return !!event['snapshot']?.data?.showLoader;
            } else {
                return false;
            }
        })
    );

    public isLocalPointedToProduction =
        window.location.hostname.includes('vizio.local') &&
        (this.env.userApiUrl.includes('vizio.com') || this.env.dataApiUrl.includes('vizio.com'));

    constructor(
        private router: Router,
        private googleAnalyticsService: GoogleAnalyticsService,
        @Inject(ENV_TOKEN) public env: IEnvironment
    ) {}

    public ngOnInit() {
        this.googleAnalyticsService.init();
    }
}
