// Angular Imports
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouteReuseStrategy, RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Project Imports
import { CoreModule } from '@core/core.module';
import { appConfig } from '@core/config/app.config';
import { FuseConfigModule } from '@fuse/services/config';
import { LayoutModule } from '@layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { ApiUrlInterceptor } from '@core/interceptors/api-url.interceptor';
import { ApiHeadersInterceptor } from '@core/interceptors/api-headers.interceptor';
import { ApiJwtRefreshInterceptor } from '@core/interceptors/api-jwt-refresh.interceptor';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';
import { AppReuseStrategy } from './app.reuse-strategy';
import { AspenSplashPageComponent } from './shared/apen-splash-page/aspen-splash-page';
import { EasterEggsContainerComponent } from './shared/easter-eggs/containers/easter-eggs-container.component';

import { AspenNavigationModule } from './core/navigation/aspen-navigation.module';
import { VizioDMPNavigationModule } from '@core/navigation/navigation-modules/vizio-dmp-navigation.module';
import { AspenPublisherPerformanceNavigationModule } from '@core/navigation/navigation-modules/publisher-performance-navigation.module';
import { PlanningNavigationModule } from '@core/navigation/navigation-modules/planning-navigation.module';
import { CampaignsNavigationModule } from '@core/navigation/navigation-modules/campaigns-navigation.module';
import { PublisherToolsNavigationModule } from '@core/navigation/navigation-modules/publisher-tools-navigation.module';
import { DeveloperToolsNavigationModule } from '@core/navigation/navigation-modules/developer-tools-navigation.module';
import { UserNavigationModule } from '@core/navigation/navigation-modules/user-navigation.module';
import { FuseModule } from '../@fuse';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager'
};

const childNavModules = [
    AspenPublisherPerformanceNavigationModule,
    PlanningNavigationModule,
    CampaignsNavigationModule,
    VizioDMPNavigationModule,
    PublisherToolsNavigationModule,
    DeveloperToolsNavigationModule,
    UserNavigationModule
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        HttpClientModule,

        // Fuse, FuseConfig
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        AspenSplashPageComponent,
        EasterEggsContainerComponent,

        AspenNavigationModule.forRoot(),
        ...childNavModules
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiUrlInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiHeadersInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiJwtRefreshInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: AppReuseStrategy
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
