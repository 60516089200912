// Angular Imports
import { computed, Injectable, Signal } from '@angular/core';

// Third Party Imports
import { getMonth } from 'date-fns';

// Project Imports
import { SignalStateServiceBase } from '@core/state-service/signal-state-service.base';

export interface IEasterEggsState {
    iconClicks: number;
}

const initialState: IEasterEggsState = {
    iconClicks: 0
};

@Injectable({
    providedIn: 'root'
})
export class EasterEggsStateService extends SignalStateServiceBase<IEasterEggsState> {
    public readonly isSpooky: Signal<boolean> = computed(() => {
        const isOctober = getMonth(new Date()) === 9;

        return this.state.iconClicks() >= 3 && isOctober;
    });

    public readonly isWinter: Signal<boolean> = computed(() => {
        const isNovToFeb = getMonth(new Date()) >= 10 || getMonth(new Date()) <= 2;

        return this.state.iconClicks() >= 3 && isNovToFeb;
    });

    constructor() {
        super(initialState);
    }

    public incrementIconClicks() {
        const currentIconClicks = this.state.iconClicks();
        const iconClicks = currentIconClicks === 3 ? 0 : currentIconClicks + 1;

        this.setState({ iconClicks });
    }
}
