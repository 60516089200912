@if ((isLoading$ | async) === false) {
    <router-outlet></router-outlet>
}
@if (isLoading$ | async) {
    <aspen-splash-page class="aspen-splash-page"></aspen-splash-page>
}

@if (isLocalPointedToProduction) {
    <div class="local-production-warning">
        <span>
            Your local environment is currently configured to point to non-local APIs. Tread
            carefully here. Update your environment.ts file to point back to local.
        </span>
    </div>
}

<easter-eggs-container></easter-eggs-container>
