// Angular Imports
import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'ee-halloween',
    templateUrl: './halloween.component.html',
    styleUrls: ['./halloween.component.scss']
})
export class HalloweenComponent {
    constructor() {}
}
